const DEVICE = {
  LAPTOP: "Laptop",
  TABLET: "Tablet",
  MOBILE: "Mobile",
};

const ROUTE = {
  ROOT: "/",
  STUDIES: "/studies",
  SUBMISSIONS: "/submissions",
  REWARDS: "/rewards",
  REDEMPTION: "/rewards/redemptions",
  SCREENING_QUESTION: "/persona",
  ACCOUNT: {
    ROOT: "account",
    MAIN_PAGE: "/account",
    PERSONAL_DATA: "personal-data",
    PASSWORD: "password",
    // CASH_DETAIL: 'cash-detail',
    CASH_DETAIL: {
      ROOT: "cash-detail",
      LINK_BANK_ACCOUNT: "cash-detail/link-bank-account",
      EDIT_VERIFY_ACCOUNT: "cash-detail/verify",
      CASH_OUT: "cash-detail/cash-out",
      CASH_OUT_HISTORY: "cash-detail/cash-out-history",
    },
    VERIFY_ACCOUNT: "verify-account",
    MY_EARNING: "my-earning",
    LANGUAGE: "language",
  },
  FIRST_TAKE_SURVEY: "/first-take-survey",
  UNAUTHEN: "/unauthen",
  ERRORFILE: "/errorfile/unauthen"
};

const ALL_ROUTES = [
  "/", 
  "/studies", 
  "/submissions", 
  "/rewards", 
  "/rewards/redemptions", 
  "/persona", 
  "/account", 
  "/account/personal-data", 
  "/account/password", 
  "/account/cash-detail", 
  "/account/cash-detail/link-bank-account", 
  "/account/cash-detail/verify", 
  "/account/cash-detail/cash-out", 
  "/account/cash-detail/cash-out-history", 
  "/account/verify-account", 
  "/account/my-earning", 
  "/account/language", 
  "/first-take-survey", 
  "/unauthen", 
  "/errorfile/unauthen"
];

const FORMAT = {
  DATE_FORMAT_WITH_TIME: "DD MMM YYYY, HH:mm",
  DATE_PICKER_FORMAT: "DD MMM YYYY",
  DATE_FORMAT: "DD MMM YYYY",
};

const SUVEY_STATUS = {
  COMPLETE: "complete",
  TIME_OUT: "time-out",
  ERROR: "error",
};

const POINT_RANK = {
  DIAMON: 50,
  GOLD: 30,
  SILVER: 10,
};

const RATE = {
  TRANSACTION_FEE: 15,
  WHT: 0.03,
};

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const CONSTANT_VALUE = {
  LIMIT_SUBMISSION_HISTORY: 10,
  LIMIT_REDEMPTION_HISTORY: 10,
  LIMIT_NOTI: 5,
};

const PROJECT_DETAIL_STATUS = {
  COMPLETED: "completed",
  IN_PROGRESS: "in-progress",
  TIME_OUT: "timeout",
  TOO_QUICK: "too-quick",
  TOO_SLOW: "too-slow",
  QUIT: "quit",
};

const REDEEM_STATUS = {
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  TIME_OUT: "timeout",
};

const TAKE_STUDY_CODE = {
  YOU_HAVE_COMPLETED: "YOU_HAVE_COMPLETED",
  YOU_HAVE_STUDIED: "YOU_HAVE_STUDIED",
  YOU_ARE_STUDYING: "YOU_ARE_STUDYING",
  YOU_ARE_STUDYING_ANOTHER_PROJECT: "YOU_ARE_STUDYING_ANOTHER_PROJECT",
  PARTICIPANT_HAS_LIMIT: "PARTICIPANT_HAS_LIMIT",
  TAKE_STUDY_SUCCESS: "TAKE_STUDY_SUCCESS",
  YOU_ARE_TIME_OUT: "YOU_ARE_TIME_OUT",
  YOU_ARE_SLOW: "YOU_ARE_SLOW",
};

const SUBMISSION_CODE = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  TIME_OUT: "TIME_OUT",
  TOO_QUICK: "TOO_QUICK",
  TOO_SLOW: "TOO_SLOW",
  QUIT: "QUIT",
};
const PAYMENT_TYPE = {
  CASH: "CASH",
  ICREDIT: "ICREDIT",
};

export {
  DEVICE,
  ROUTE,
  FORMAT,
  SUVEY_STATUS,
  POINT_RANK,
  RATE,
  PASSWORD_REGEX,
  CONSTANT_VALUE,
  PROJECT_DETAIL_STATUS,
  TAKE_STUDY_CODE,
  SUBMISSION_CODE,
  PAYMENT_TYPE,
  REDEEM_STATUS,
  ALL_ROUTES
};
